export default () => ({
  name: '',
  domain: '',
  country: undefined,
  fbPage: '',
  instagram: '',
  telegram: '',
  wsPhone: '',
  advisor: '',
  coverPageImage: '',
  topBannerText: '',
  paymentMethods: undefined,
  mainColor: '',
  isDefault: false,
  mercadopagoAccessToken: ''
});
